#untappd,
#lastfm,
#github {
    grid-column: 1/3;
    display: grid;
    align-content: center;

    svg {
        min-height: 3rem;
        width: 3em;
        order: -1;
        grid-column: 1;
    }
}

#untappd-stats,
#lastfm-stats,
#github-stats {
    line-height: 0;
    font-size: .6em;
    grid-column: 2;
    display: inline-grid;
    justify-items: right;

    p {
        &:first-child {
            font-family: 'Anonymous Pro Bold';
        }

        font-family: 'Anonymous Pro';
    }
}

#untappd-stats p,
#lastfm-stats p,
#github-stats p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    line-height: 1;
    margin: .25rem 0;
    max-width: 85%;
}
