html {
    font-size: 16px;
    font-family: 'Forum';
    overflow-x: hidden;
}

@media screen and (min-width: 768px) {
    html {
        font-size: calc(16px + 8 * ((100vw - 768px) / 768));
    }
}

@font-face {
    font-family: Forum;
    font-style: normal;
    font-weight: 400;
    src: local(Forum), url(//fonts.gstatic.com/s/forum/v7/ZqpICanVEXplF5Afx-0HCw.eot?#) format('eot'), url(//fonts.gstatic.com/s/forum/v7/eMDvaUkVr_spjjcEeqYI5A.woff2) format('woff2'), url(//fonts.gstatic.com/s/forum/v7/KsJrhVlKA7udJPNqNdl2fA.woff) format('woff');
}

@font-face {
    font-family: Megrim;
    font-style: normal;
    font-weight: 400;
    src: local(Megrim), url(//fonts.gstatic.com/s/megrim/v7/1f5HoozKyYXswla7UhCq0A.eot?#) format('eot'), url(//fonts.gstatic.com/s/megrim/v7/3QlnWQVA-dQyEeamqiHm2g.woff2) format('woff2'), url(//fonts.gstatic.com/s/megrim/v7/wEAANqPdhC-qap-OX73FcA.woff) format('woff');
}

@font-face {
    font-family: "Anonymous Pro";
    font-style: normal;
    font-weight: 400;
    src: local("Anonymous Pro"), local(AnonymousPro), url(//fonts.gstatic.com/s/anonymouspro/v8/Zhfjj_gat3waL4JSju74E3Xp_Gpx0-4m3wAn7HKLs74.eot?#) format("eot"), url(//fonts.gstatic.com/s/anonymouspro/v8/Zhfjj_gat3waL4JSju74ExEOS6Aq7hJAAidQNR3rgdU.woff2) format("woff2"), url(//fonts.gstatic.com/s/anonymouspro/v8/Zhfjj_gat3waL4JSju74E1tUcs43uvLUMv3hfHgzs3w.woff) format("woff");
}

@font-face {
    font-family: "Anonymous Pro Bold";
    font-style: normal;
    font-weight: 700;
    src: local("Anonymous Pro Bold"), local(AnonymousPro-Bold), url(//fonts.gstatic.com/s/anonymouspro/v8/WDf5lZYgdmmKhO8E1AQud30lRvvQrNeWza1BnlT_HW4.eot?#) format("eot"), url(//fonts.gstatic.com/s/anonymouspro/v8/WDf5lZYgdmmKhO8E1AQud8GfYvzQ_DwqEC2arHsRzXk.woff2) format("woff2"), url(//fonts.gstatic.com/s/anonymouspro/v8/WDf5lZYgdmmKhO8E1AQudy_O50XgJwSsgBhwZ9pjuTs.woff) format("woff");
}

h1,
h2 {
    font-family: 'Megrim';
}

#logo {
    max-width: 616px;
    min-height: 90vh;
    margin: 0 auto;
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px) {
        max-width: 65vw;
        margin: -1em auto 0;
    }

    @media screen and (max-width: 768px) {
        align-items: self-start;
        padding-top: 5em;
    }

    svg {
        overflow: visible;
    }
}

canvas {
    width: 100vw;
    min-height: 768px;
    max-height: 100vh;
    position: absolute;
    top: 0;
    z-index: -1;
    box-shadow: 0 3px .5em #322;
}

.wrapper {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 15em 20em;
    justify-content: center;
    align-content: space-around;
    position: relative;
    margin: 0;
    width: 100vw;

    @media screen and (max-width: 768px) {
        grid-template-columns: calc(40vw - 1em) calc(60vw - 1em);
    }
}

#profile {
    margin-top: -2.5em;
    padding: 0;
    display: grid;
    justify-content: right;

    svg {
        @media screen and (max-width: 768px) {
            height: 445px;
            margin-right: -5em;
        }
    }
}

#notice {
    align-self: end;
}

#skills {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-gap: 1em;

    ul {
        order: 2;
        list-style: none;
        padding: 0;
        display: grid;
        align-content: space-around;
        grid-template-columns: repeat(2, 1fr);

        @media screen and (max-width: 768px) {
            order: 0;
        }

        li {
            display: inline-block;

            &:nth-child(odd) {
                text-align: right;
                padding-right: .5em;
                border-right: 3px double rgba(0, 0, 0, .15);
                margin-right: .5em;
            }
        }
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: initial;
        grid-template-rows: 50% 50%;

        svg {
            width: 50%;
            align-self: center;
            justify-self: center;
        }
    }
}

#work {
    grid-column: 1 / 3;
}

#experience {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: 47.5% 47.5%;
    grid-gap: 0 5%;

    li {
        display: grid;
        align-content: start;

        h3 {
            margin: 0;
        }

        h4 {
            margin: .5em 0;
        }

        a {
            display: list-item;
            color: #fe5f55;
            transition: .4s color;
            text-decoration: none;

            &:hover {
                color: lighten(#fe5f55, 10%);
            }
        }

        &:nth-child(odd) {
            justify-items: right;
            text-align: right;
        }

        &:nth-child(even) {
            margin-top: 2.5em;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

#social {
    display: grid;
    grid-template-columns: repeat(2, 40%);
    grid-gap: 0 15%;
    align-self: start;

    @media only screen and (max-width: 736px) {
        grid-template-columns: initial;
        grid-column: 1/3;
        grid-gap: 1em;
    }

    a {
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        z-index: 1;
        box-shadow: 0 0 0 0 rgba(255, 255, 255, .95);

        &:hover {
            box-shadow: 0 0 0 .375em rgba(255, 255, 255, 0);
            transition: .4s box-shadow;
        }

        ~ svg {
            transition: .4s transform;
        }

        &:hover ~ svg {
            transform: scale(1.1);
        }
    }
}

#contact {
    margin-bottom: 5.5em;

    @media only screen and (max-width: 736px) {
        grid-column: 1/3;
    }
}

#jobs,
#message {
    display: grid;
    grid-gap: .25em;
    grid-template-columns: 20% 80%;

    svg {
        order: -1;
    }
}

#jobs {
    grid-template-columns: 10% 90%;

    @media only screen and (max-width: 736px) {
        grid-template-columns: 15% 85%;
    }

    svg {
        height: 2em;
        width: 2em;
        margin-top: -.5em;
    }
}

#message {
    @media only screen and (max-width: 736px) {
        grid-gap: 1em;
        grid-template-columns: 10% 90%;
    }
}

#skills,
#work,
#contact,
#friend {
    svg:hover {
        cursor: pointer;
    }
}

.box {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .25);
    box-shadow: 0 0 .5em rgba(0, 0, 0, .05);
    color: #333;
    padding: 1em;
    margin: 1em 0;
    overflow: hidden;

    #social & {
        height: 3em;
        position: relative;
        overflow: visible;

        @media only screen and (max-width: 736px) {
            margin: 0;
        }
    }
}

#friend {
    height: 62px;
    padding: 0 0 1.5em;
    margin: -5.5em 0 0;
    text-align: right;
    grid-column: 1/3;

    svg {
        width: 64px;
    }
}
